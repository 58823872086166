import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../components/Input";

const DateTime = () => {
  const [date, setDate] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const dispatch = useDispatch();

  const updateDateTime = () => {
    Office.context.mailbox.item.start.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const appointmentStart = new Date(result.value);
        const formattedDate = appointmentStart.toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        const hours = String(appointmentStart.getHours()).padStart(2, "0");
        const minutes = String(appointmentStart.getMinutes()).padStart(2, "0");
        const formattedTime = `${hours}:${minutes}`;

        setDate(formattedDate);
        setArrivalTime(formattedTime);

        const dateTimeForRedux = `${appointmentStart.getFullYear()}-${String(appointmentStart.getMonth() + 1).padStart(
          2,
          "0",
        )}-${String(appointmentStart.getDate()).padStart(2, "0")}T${formattedTime}`;

        dispatch({
          type: "SET_APPOINTMENT_DATA",
          payload: { date_time: dateTimeForRedux },
        });
      } else {
        console.error(`Action failed with message ${result.error.message}`);
      }
    });
  };

  useEffect(() => {
    updateDateTime();

    Office.context.mailbox.item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, updateDateTime);

    return () => {
      Office.context.mailbox.item.removeHandlerAsync(Office.EventType.AppointmentTimeChanged, updateDateTime);
    };
  }, []);

  return (
    <Input
      label="Datum und Ankunftszeit"
      value={`${date} / ${arrivalTime} Uhr`}
      className="pointer-events-none"
      readOnly
    />
  );
};

export default DateTime;
